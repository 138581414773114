<template>
    <div>
        <div class="personal-settings__wrapper">
            <h1 class="title">{{ $t('user.personalSettings') }}</h1>
            <div class="personal-settings__block">
                <h2 class="mb-20">{{ $t('user.logInSettings') }}</h2>
                <CRInput
                    type="password"
                    v-model="passwordForm.currentPassword"
                    :placeholder="$t('placeholder.enterCurrentPassword')"
                    :label="$t('common.currentPassword')"
                    :errors="passwordErrors?.currentPassword"
                    @onChange="$filters.clearError(passwordErrors, 'currentPassword')"
                />
                <CRInput
                    type="password"
                    v-model="passwordForm.password"
                    :placeholder="$t('placeholder.enterNewPassword')"
                    :label="$t('common.newPassword')"
                    :errors="passwordErrors?.password"
                    @onChange="$filters.clearError(passwordErrors, 'password')"
                />
                <CRInput
                    type="password"
                    v-model="passwordForm.passwordConfirmation"
                    :placeholder="$t('placeholder.enterConfirmNewPassword')"
                    :label="$t('common.confirmNewPassword')"
                    @onChange="$filters.clearError(passwordErrors, 'password')"
                />
                <CRButton :disabled="updatePasswordButtonDisabled" pattern="thin" @click="updatePassword">
                    {{ $t('user.updatePassword') }}
                </CRButton>
            </div>
            <div class="personal-settings__block">
                <h2 class="mb-20">{{ $t('user.personalInformation') }}</h2>
                <CRInput
                    v-model="form.username"
                    :required="true"
                    :placeholder="$t('user.username')"
                    :label="$t('user.username')"
                    :errors="formErrors?.username"
                    @onChange="$filters.clearError(formErrors, 'username')"
                />
                <CRInput
                    v-model="v$.form.firstName.$model"
                    :placeholder="$t('user.realName')"
                    :label="$t('user.realName')"
                    :errors="v$.form.firstName.$errors"
                    is-vuelidate
                />
                <CRInput
                    v-model="v$.form.lastName.$model"
                    :placeholder="$t('user.surname')"
                    :label="$t('user.surname')"
                    :errors="v$.form.lastName.$errors"
                    is-vuelidate
                    @onChange="$filters.clearError(formErrors, 'lastName')"
                />
                <CRInput
                    v-model="v$.form.companyName.$model"
                    :placeholder="$t('user.companyName')"
                    :label="$t('user.companyName')"
                    :errors="v$.form.companyName.$errors"
                    is-vuelidate
                    @onChange="$filters.clearError(formErrors, 'companyName')"
                />
            </div>
            <div class="personal-settings__block">
                <h2 class="mb-20">{{ $t('user.contacts') }}</h2>
                <CRInput
                    v-model="v$.form.phone.$model"
                    :placeholder="$t('user.phoneNumber')"
                    :label="$t('user.phoneNumber')"
                    :errors="v$.form.phone.$errors"
                    is-vuelidate
                    @onChange="$filters.clearError(formErrors, 'phone')"
                />
                <CRInput
                    v-model="v$.form.email.$model"
                    :required="true"
                    :placeholder="$t('user.email')"
                    :label="$t('user.email')"
                    :errors="v$.form.email.$errors"
                    is-vuelidate
                    @onChange="$filters.clearError(formErrors, 'email')"
                />
            </div>
            <div class="btn-section">
                <RequiredText />
                <CRButton :loading="isLoading" :disabled="saveButtonDisabled" @click="updateUserData">
                    {{ $t('buttons.save') }}
                </CRButton>
            </div>
        </div>
    </div>
</template>

<script>
    import CRInput from '@/components/Common/Inputs/CRInput';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import RequiredText from '@/components/Common/Tags/RequiredText';
    import { mapActions, mapGetters } from 'vuex';
    import { UserApi } from '@/api';
    import { TYPE } from 'vue-toastification';
    import { useVuelidate } from '@vuelidate/core';
    import { minLengthRule, maxLengthRule, phoneRule, requiredRule, emailRule } from '@/helpers/VuelidateRuleHelper';

    const PERSONAL_SETTING_RELATED_FIELDS = ['username', 'email', 'firstName', 'lastName', 'phone', 'companyName'];

    const INITIAL_PASSWORD_FORM_DATA = {
        currentPassword: '',
        password: '',
        passwordConfirmation: '',
    };

    export default {
        name: 'PersonalSettings',
        components: { RequiredText, CRButton, CRInput },
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                form: {
                    username: '',
                    firstName: '',
                    lastName: '',
                    companyName: '',
                    phone: '',
                    email: '',
                },
                passwordForm: { ...INITIAL_PASSWORD_FORM_DATA },

                formErrors: {},
                passwordErrors: {},

                isLoading: false,
            };
        },
        validations() {
            return {
                form: {
                    firstName: {
                        minLength: minLengthRule(2),
                        maxLength: maxLengthRule(100),
                    },
                    lastName: {
                        minLength: minLengthRule(2),
                        maxLength: maxLengthRule(100),
                    },
                    companyName: {
                        minLength: minLengthRule(2),
                        maxLength: maxLengthRule(100),
                    },
                    phone: {
                        minLength: minLengthRule(9),
                        phone: phoneRule(),
                    },
                    email: {
                        required: requiredRule(),
                        email: emailRule(),
                    },
                },
            };
        },
        computed: {
            ...mapGetters('auth', ['user']),

            saveButtonDisabled() {
                return !this.form.username || !this.form.email;
            },

            updatePasswordButtonDisabled() {
                return (
                    !this.passwordForm.currentPassword ||
                    !this.passwordForm.password ||
                    !this.passwordForm.passwordConfirmation
                );
            },
        },
        methods: {
            ...mapActions('auth', ['updateUser']),

            async updateUserData() {
                this.isLoading = true;

                try {
                    const result = await this.v$.$validate();
                    if (!result) {
                        this.$filters.toast(this.$t('errors.someFieldsAreNotFilledCorrectly'));
                        return;
                    }

                    await this.updateUser({ id: this.user.id, data: this.form });

                    this.$filters.toast(this.$t('user.personalInformationChangedSuccess'), { type: TYPE.SUCCESS });
                } catch (error) {
                    this.formErrors = error.errors;
                } finally {
                    this.isLoading = false;
                }
            },

            async updatePassword() {
                try {
                    await UserApi.update(this.user.id, this.passwordForm);

                    this.$filters.toast(this.$t('user.passwordChangedSuccess'), { type: TYPE.SUCCESS });

                    this.passwordForm = { ...INITIAL_PASSWORD_FORM_DATA };
                } catch (error) {
                    this.passwordErrors = error.errors;
                }
            },

            setDataForFields() {
                Object.keys(this.user).forEach((key) => {
                    if (PERSONAL_SETTING_RELATED_FIELDS.includes(key)) this.form[key] = this.user[key];
                });
            },
        },
        mounted() {
            this.setDataForFields();
        },
    };
</script>

<style scoped lang="scss">
    .personal-settings__wrapper {
        max-width: 633px;
        .title {
            display: none;

            @include media($lg) {
                display: block;
                margin-bottom: 30px;
            }
        }

        .personal-settings__block {
            margin-top: 20px;

            * {
                margin-bottom: 30px;
            }
        }

        .btn-section {
            margin-bottom: 70px;
        }
    }
</style>
