<template>
    <p class="tooltip">{{ text ? text : this.$t('common.requiredFields') }}</p>
</template>

<script>
    export default {
        name: 'RequiredText',
        props: {
            text: {
                type: String,
                default: '',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .tooltip {
        margin: 18px 0;

        font-style: italic;
        font-weight: 300;
    }
</style>
