import { helpers, minLength, maxLength, requiredIf, required, email } from '@vuelidate/validators';
import { translation } from '@/helpers/TranslationHelper';

export const minLengthRule = (min) => {
    return helpers.withMessage(
        ({ $params }) => translation('errors.minLength', { amount: $params.min }),
        minLength(min)
    );
};

export const maxLengthRule = (max) => {
    return helpers.withMessage(
        ({ $params }) => translation('errors.maxLength', { amount: $params.max }),
        maxLength(max)
    );
};

export const requiredIfRule = (condition) => {
    return helpers.withMessage(translation('errors.required'), requiredIf(condition));
};

export const requiredRule = () => {
    return helpers.withMessage(translation('errors.required'), required);
};

export const phoneRule = () => {
    return helpers.withMessage(translation('errors.phone'), helpers.regex(/^(\+3706)\d{3}\d{4}$/));
};

export const emailRule = () => {
    return helpers.withMessage(translation('errors.email'), email);
};
